import React from "react";

import styled from "styled-components";

const BlogWapper = styled.div`
	width: 100%;
	max-width: 1280px;
	margin: 0 auto;
`;

const ImageWapper = styled.div`
	width: 100%;
	height: 660px;
	position: relative;
	z-index: 1;

	@media (max-width: 960px) {
		height: 550px;
	}

	@media (min-width: 481px) and (max-width: 768px) {
		height: 450px;
	}

	@media (min-width: 320px) and (max-width: 480px) {
		height: 350px;
	}

	@media (max-width: 320px) {
		height: 250px;
		width: 100%;
	}
`;

const Hero_image = styled.div`
	width: 100%;
	height: 100%;
	background-image: url(${(props) => props.background});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top;
	position: absolute;
	top: 0;
`;

const PostTitle = styled.div`
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	padding: 0.5rem;
	background-color: #fff;
	margin: 0 auto;

	.blogTitle {
		width: 100%;
		padding: 0.5rem;
		margin-top: 1rem;
		h1 {
			font-size: 2.2rem;
			font-weight: bold;
		}

		h5 {
			font-size: 0.8rem;
			font-family: "GillSans", "sans";
			font-weight: 500;
			margin-right: 0.3rem;
			padding-top: 1rem;
			padding-bottom: 0.7rem;
		}
	}

	@media (max-width: 960px) {
		flex-flow: row wrap;
		height: 100%;
		img {
			width: 100%;
			order: 0;
		}
		.blogTitle {
			width: 100%;
			order: 0;
			align-self: center;

			h1 {
				font-size: 1.6rem;
				padding-top: 1.4rem;
				padding-bottom: 1.4rem;
			}
		}
	}
`;

const PostContent = styled.div`
	max-width: 1160px;
	margin: 0 auto;
	padding: 1rem;
	overflow: hidden;
	font-family: "GillSans", "sans";
	font-weight: 500;
	font-size: 1.1rem;
	line-height: 2.2;
	margin-bottom: 2rem;

	img {
		width: 70%;
		display: block;
		margin: 0 auto;
		text-align: center;
		padding: 0.5rem;
		margin-top: 1rem;
		margin-bottom: 1rem;

		@media (max-width: 960px) {
			width: 100%;
			padding: 0;
		}
	}
`;

const BlogPageLayout = (props) => {
	return (
		<>
			<ImageWapper>
				<Hero_image background={props.featureImage}></Hero_image>
			</ImageWapper>
			<BlogWapper>
				<PostTitle>
					<div className="blogTitle">
						<h1 dangerouslySetInnerHTML={{ __html: props.title }} />
						<h5>
							By {props.authorName} | {props.postDate}
						</h5>
					</div>
				</PostTitle>
				<PostContent>
					<div dangerouslySetInnerHTML={{ __html: props.postContent }} />
				</PostContent>
			</BlogWapper>
		</>
	);
};

export default BlogPageLayout;
