import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../components/Layout/Layout";
import BlogPageLayout from "../components/BlogPageLayout";

const Post = (props) => {
	const post = props.data.wordpressPost;
	console.log(post);
	return (
		<Layout>
			<BlogPageLayout
				featureImage={post.featured_media.source_url}
				title={post.title}
				authorName={post.author.name}
				postDate={post.date}
				postContent={post.content}
			/>
		</Layout>
	);
};

Post.propTypes = {
	data: PropTypes.object.isRequired,
	edges: PropTypes.array,
};

export default Post;

export const postQuery = graphql`
	query($id: String!) {
		wordpressPost(id: { eq: $id }) {
			title
			content
			featured_media {
				source_url
			}
			date(formatString: "MMMM Do YYYY")
			author {
				name
			}
		}
	}
`;
